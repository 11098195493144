import axios from 'axios'
import { Notification } from 'element-ui'

export default {
    namespaced: true,
    state: () => ({
        editModal: {
            competitor: {},
            isOpen: false,
            isLoading: false
        },
    }),
    getters: {},
    mutations: {
        setEditModal(state, payload) {
            state.editModal.isOpen = payload
        },
        setCompetitor(state, payload) {
            state.editModal.competitor = payload
        }
    },
    actions: {
        async openEditModal({ commit, rootState }, id) {
            await axios.get(`/api/projects/${rootState.projects.currentProject}/${id}`, {
                headers: {
                    "Authorization": localStorage.getItem('accessToken')
                }
            })
                .then((response) => {
                    commit('setCompetitor', response.data)
                    commit('setEditModal', true)
                })
                .catch((e) => {
                    Notification.error({
                        title: 'Ошибка API',
                        message: e.response.data
                    })
                })
        },
        async saveEditCompetitor({ commit, rootState, state }) {
            await axios.put(`/api/projects/${rootState.projects.currentProject}/${state.editModal.competitor.id}`, {
                ...state.editModal.competitor
            },{
                headers: {
                    "Authorization": localStorage.getItem('accessToken')
                }
            })
                .then(() => {
                    commit('setEditModal', false)
                    commit('setCompetitor', {})
                })
                .catch((e) => {
                    Notification.error({
                        title: 'Ошибка API',
                        message: e.response.data
                    })
                })
        }
    }
}