import axios from 'axios'
import { Notification } from 'element-ui'

export default {
    namespaced: true,
    state: () => ({
        isLoading: false,
        projects: [],
        currentProject: null,
        createModal: {
            isOpen: false,
            isLoading: false
        },
    }),
    getters: {},
    mutations: {
        loadProjects(state, payload) {
            state.projects = payload
        },
        choseProject(state, payload) {
            state.currentProject = payload
        },
        setCreateModal(state, payload) {
            state.createModal.isOpen = payload
        },
        setCreateLoading(state, payload) {
            state.createModal.isLoading = payload
        }
    },
    actions: {
        getProjects: async ({ commit }) => {
            await axios.get('/api/projects', {
                headers: {
                    "Authorization": localStorage.getItem('accessToken')
                }
            })
                .then((response) => {
                    commit('loadProjects', response.data)
                })
                .catch((e) => {
                    Notification.error({
                        title: 'Ошибка API',
                        message: e.response.data
                    })
                })
        },
        createProject: async ({ commit, dispatch }, name) => {
            commit('setCreateLoading', true)
            await axios.post('/api/projects', {
                name
            }, {
                headers: {
                    "Authorization": localStorage.getItem('accessToken')
                }
            })
                .then(() => {
                    dispatch('getProjects')
                    commit('setCreateModal', false)
                })
                .catch((e) => {
                    Notification.error({
                        title: 'Ошибка API',
                        message: e.response.data.message
                    })
                }).finally(() => {
                    commit('setCreateLoading', false)
                })
        },
    }
}