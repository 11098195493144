import Vue from 'vue'
import VueDateFns from 'vue-date-fns'
import ReadMore from 'vue-read-more'
import ElementUI from 'element-ui'

import App from './App.vue'
import router from './router'

import locale from 'element-ui/lib/locale/lang/ru-RU'
import './assets/styles/element-variables.scss'
import './assets/styles/normilize.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faAnglesRight, faAnglesLeft, faChalkboardUser,faFireFlameCurved,faPhotoFilm,faHamsa, faAt, faCodeBranch, faDatabase, faFan, faG, faCog, faCheck, faMinus } from '@fortawesome/free-solid-svg-icons'
import { faHeart, faComment,} from '@fortawesome/free-regular-svg-icons'
import { faInstagram, faGoogle, faYandex, faFacebookF, faApple, faChrome, faDocker, faFlickr, faGithub, faGooglePlay, 
faLinkedinIn, faReact} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from './store'


Vue.use(VueDateFns);
Vue.use(ElementUI, { locale });
Vue.use(ReadMore);
library.add(
    faUserSecret,
    faInstagram,
    faGoogle,
    faYandex,
    faAnglesRight,
    faAnglesLeft,
    faChalkboardUser,
    faFireFlameCurved,
    faFacebookF,
    faPhotoFilm,
    faHamsa,
    faHeart,
    faComment,
    faApple,
    faAt,
    faChrome,
    faCodeBranch,
    faDatabase,
    faDocker,
    faFan,
    faFlickr,
    faG,
    faGithub,
    faGooglePlay,
    faLinkedinIn,
    faReact,
    faCog,
    faCheck,
    faMinus
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '682406182072-apc9n55ng01lrs20d87731kijjkdn0n6.apps.googleusercontent.com',
  scope: 'profile email https://www.googleapis.com/auth/business.manage',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
