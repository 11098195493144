import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.state.auth.refreshToken) {
    next()
    return
  }
  next("/dashboard")
}

const ifAuthenticated = (to, from, next) => {
  if (store.state.auth.refreshToken) {
    next()
    return
  }
  next("/sign-in")
}

const routes = [
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "about" */ '../views/SignupView.vue'),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppView.vue'),
    beforeEnter: ifAuthenticated,
    children:[
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/DashboardView.vue')
      },
      {
        path: '/competitors',
        name: 'competitors',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/CompetitorsView.vue')
      },
      {
        path: '/smmbot',
        name: 'smmbot',
        component: () => import(/* webpackChunkName: "about" */ '../views/SmmbotView.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "about" */ '../views/SettingsView.vue')
      },
    ]
  },  
] 

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
