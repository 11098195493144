import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import projects from './projects'
import competitors from './competitors'

Vue.use(Vuex)

export default new Vuex.Store({
  devtools: true,
  state: {
    isInfoCompetitorModalOpen: false
  },
  getters: {
  },
  mutations: {
    openmodal (state) {
      state.isInfoCompetitorModalOpen=true
    },
    closemodal (state) {
      state.isInfoCompetitorModalOpen=false
    }
  },
  actions: {
  },
  modules: {
    auth,
    projects,
    competitors
  }
})
