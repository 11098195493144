<template>
  <div id="app" v-loading="$store.state.auth.isLoading">
    <router-view v-if="!$store.state.auth.isLoading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('auth/initialLoading')
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}
html, body, #app {
  font-family: 'Inter', sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  /* overflow: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>