import axios from 'axios'
import { Notification } from 'element-ui'
import router from '@/router'

export default {
    namespaced: true,
    state: () => ({
        isLoading: true,
        isSignInLoading: false,
        isAuth: false,
        refreshToken: localStorage.getItem('refreshToken') || null,
    }),
    getters: {
        isTokenValid: () => {
            const expiredIn = window.localStorage.getItem('expiresIn') || '0'
            const currentDatetime = Date.now()
            const expiredDatetime = parseInt(expiredIn, 10)
            return currentDatetime < expiredDatetime
        }
    },
    mutations: {
        setLoading(state, payload) {
            state.isLoading = payload
        },
        setSignInLoading(state, payload){
            state.isSignInLoading = payload
        },
        signUpSuccess(state, payload) {
            state.refreshToken = payload
            state.isAuth = true
        },
        refreshSuccess(state, payload) {
            state.refreshToken = payload
            state.isAuth = true
        },
        refreshError(state) {
            state.refreshToken = null
        }
    },
    actions: {
        initialLoading: ({ state, commit, dispatch }) => {
            if(!state.refreshToken) {
                commit('setLoading', false)
                return
            }
            dispatch('refreshToken')
        },
        refreshToken: async ({ state, commit, dispatch }) => {
            await axios.post('/api/auth/refresh', {
                refreshToken: state.refreshToken
            })
                .then((response) => {
                    localStorage.setItem('accessToken', response.data.accessToken)
                    localStorage.setItem('refreshToken', response.data.refreshToken)
                    localStorage.setItem('expiresIn', response.data.expiresIn)
                    commit('refreshSuccess', response.data.refreshToken)
                })
                .catch(() => {
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('expiresIn')
                    commit('refreshError')
                }).finally(() => {
                    commit('setLoading', false)
                    setTimeout(() => {
                        dispatch('refreshToken')
                    }, 14 * 60 * 1000)
                })
        },
        signIn: ({ commit, dispatch }, { email, password }) => {
            commit('setSignInLoading', true)
            axios.post('/api/auth/sign-in', {
                email,
                password
            })
                .then(async (response) => {
                    localStorage.setItem('accessToken', response.data.accessToken)
                    localStorage.setItem('refreshToken', response.data.refreshToken)
                    localStorage.setItem('expiresIn', response.data.expiresIn)
                    commit('signUpSuccess', response.data.refreshToken)
                    await router.push('/dashboard')
                })
                .catch(() => {
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('expiresIn')
                    Notification.error({
                        title: 'Ops',
                        message: 'Логин или пароль не верный'
                    })
                })
                .finally(() => {
                    commit('setSignInLoading', false)
                    setTimeout(() => {
                        dispatch('refreshToken')
                    }, 14 * 60 * 1000)
                })
        }
    }
}